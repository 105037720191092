import { createReducer } from 'deox';
import produce from 'immer';
import { removeFromArray } from 'src/utils/helpers';
import {
  addToCard,
  getOrder,
  removeFromCart,
  resetSingleTour,
  resetStoredCartData,
  resetTours,
  setCartCount,
  setOrder,
  setTourById,
  setTours,
} from './actions';
import { tourState } from './types';

const hasTours = localStorage.cartToursArray;

export const toursInitialState: tourState = {
  tours: [],
  singleTour: null,
  cartToursArray: hasTours ? JSON.parse(localStorage.cartToursArray) : [],
  cartCount: localStorage.cartCount ? +JSON.parse(localStorage.cartCount) : 0,
};

export const tourReducer = createReducer(toursInitialState, (handle) => [
  handle(setOrder, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft[payload.name] = payload.value;
    })
  ),
  handle(setCartCount, (state, { payload }: any) =>
    produce(state, (draft) => {
      sessionStorage.setItem('cartCount', JSON.stringify(payload));
      draft.cartCount = payload;
    })
  ),
  handle(getOrder, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.isLoading = payload.isLoading;
    })
  ),
  handle(setTours, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.tours = payload;
    })
  ),
  handle(resetTours, (state) =>
    produce(state, (draft: any) => {
      draft.tours = [];
    })
  ),
  handle(setTourById, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      draft.singleTour = payload;
    })
  ),
  handle(resetSingleTour, (state) =>
    produce(state, (draft: any) => {
      draft.singleTour = null;
    })
  ),
  handle(addToCard, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      localStorage.setItem('cartToursArray', JSON.stringify([...state.cartToursArray, payload]));
      localStorage.setItem('cartCount', JSON.stringify(+state.cartCount + 1));

      draft.cartToursArray = [...state.cartToursArray, payload];
      draft.cartCount = +state.cartCount + 1;
    })
  ),
  handle(removeFromCart, (state, { payload }: any) =>
    produce(state, (draft: any) => {
      const newTours = removeFromArray(state.cartToursArray, (tour: any) => tour.id === payload);
      localStorage.setItem('cartToursArray', JSON.stringify(newTours));
      localStorage.setItem('cartCount', JSON.stringify(+state.cartCount - 1));

      draft.cartToursArray = newTours;
      draft.cartCount = +state.cartCount - 1;
    })
  ),
  handle(resetStoredCartData, (state) =>
    produce(state, (draft: any) => {
      draft.cartToursArray = [];
      draft.cartCount = 0;
    })
  ),
]);
