/**
 * Routes for all available project pages
 */
export enum EnumRoutePaths {
  // Root page
  BASE = '/',
  CHECKING = '/order-checking',
  BASE_1 = '/:location/',
  AI_SUGGESTIONS = '/:location/ai',
  CAREGORIES = '/:location/categories',
  CAREGORY_TOUR = '/:location/categories/:id',
  TOUR_BOOKING = '/:location/tour-booking',
  VIP_TOURS = '/:location/vip-tours',
  ABOUT = '/:location/about',
  PRIVACY = '/:location/privacy',
  CART = '/:location/cart',
  TAXI = '/:location/taxi',
}
