import { Carousel } from 'antd';
import cn from 'classnames';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { SvgIcon } from 'src/components/atoms/SvgIcon';
import { IRootState } from 'src/types/store-types';
import { convertToImgix } from 'src/utils/transformers';
import SliderContentDesc from '../SliderContentDesc';
import './styles.scss';

const Slider: FC = () => {
  const carousel: any = useRef(null);
  const { imagesArr } = useSelector((state: IRootState) => state.app);
  const [index, setIndex] = useState(0);
  const handleChange = (): void => {
    setIndex(index + 1);
  };
  const handleSliderChange = (isNext: boolean): void => {
    if (isNext) {
      carousel.current.next();
      setIndex(index + 1);
    } else {
      carousel.current.prev();
      setIndex(index - 1);
    }
  };

  return (
    <div className="slider">
      <Carousel afterChange={() => handleChange()} className="carousel-container" ref={carousel} autoplay>
        {imagesArr?.map((image, i) => (
          <div key={i} className="carusel-item-ontainer">
            <img rel="preload" className="image" src={convertToImgix(image)} alt={image} />
          </div>
        ))}
      </Carousel>

      <div
        className={cn('bottom-container', {
          hideButtom: imagesArr.length < 2,
        })}
      >
        <div className="button-prev link" onClick={() => handleSliderChange(false)}>
          <div className="icon-container">
            <SvgIcon src="/images/icons/leftArrow.svg" />
          </div>
        </div>
        <div className="button-prev link" onClick={() => handleSliderChange(true)}>
          <div className="icon-container">
            <SvgIcon src="/images/icons/rightArrow.svg" />
          </div>
        </div>
      </div>
      <SliderContentDesc />
    </div>
  );
};

export default Slider;
